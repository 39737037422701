<template>
  <v-col>
    <v-row class="ma-0 pa-0 align-end flex-wrap w-full">
      <h1 class="mr-2">Check-in</h1>
      <v-row class="w-full pa-2 mt-2">
        <v-btn
          elevation="0"
          outlined
          rounded
          :color="showBaixaManual ? 'primary' : 'black'"
          @click="openBaixaManual()"
          class="mr-1"
        >
          <v-icon>mdi-magnify</v-icon>
          Manual
        </v-btn>
        <v-btn elevation="0" rounded color="primary" @click="openQrCode()">
          <v-icon>mdi-qrcode</v-icon>
          QRCODE
        </v-btn>
      </v-row>
    </v-row>
    <Timeline v-if="!showBaixaManual && !showQrCode" />
    <BaixaManual v-if="showBaixaManual" @close="showBaixaManual = false" />
    <LeitorQrCode v-if="showQrCode" @close="closeQrCode()" />
  </v-col>
</template>

<script>
import Timeline from "./timeline.vue";
import BaixaManual from "./baixaManual.vue";
import LeitorQrCode from "./leitorQrCode.vue";
import { Role } from "@/constants";

export default {
  components: {
    Timeline,
    BaixaManual,
    LeitorQrCode,
  },
  data() {
    return {
      mobile: eval(process.env.VUE_APP_IS_MOBILE),
      showBaixaManual: false,
      showQrCode: false,
      user: this.$store.getters["user/getUser"],
    };
  },
  beforeMount() {
    this.checkUser();
  },

  created() {
    if (this.$store.getters["user/getUser"].roleId == Role.Comissario)
      return this.$router.push("/comissarios");
    /*     if (this.isMobile) {
      this.$router.push("/check-in");
    }

    if (
      this.$store.getters["user/getUser"].roleId !== Role.ROOT &&
      this.$store.getters["user/getUser"].roleId !== Role.USER_PARCEIRO &&
      this.$store.getters["user/getUser"].roleId !== Role.ADMIN_PARCEIRO
    ) {
      this.$router.push("/dashboard");
    } */
  },
  methods: {
    openQrCode() {
      this.showBaixaManual = false;
      this.showQrCode = true;
    },
    openBaixaManual() {
      this.showBaixaManual = true;
      this.showQrCode = false;
    },
    closeQrCode() {
      this.showBaixaManual = false;
      this.showQrCode = false;
    },
    checkUser() {
      if (
        this.user == Role.ADMIN_PARCEIRO ||
        Role.USER_PARCEIRO ||
        Role.ROOT ||
        Role.Comissario
      ) {
        console.log("user permitido");
      } else {
        this.$root.showErro("Utilize um usuário Parceiro");
        setTimeout(() => {
          this.$store.commit("user/setTokenAuth", "");
          this.$store.commit("user/setAuthStatus", false);
          window.location.reload();
        }, 3000);
      }
    },
  },
};
</script>

<style>
.w-full {
  width: 100%;
}
</style>
