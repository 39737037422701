<template>
<div>
  <v-row>
    <v-col sm="12" md="6" lg="6" class="ma-0 mt-4 pa-0">
      <v-btn text color="primary" class="px-6" @click="voltar">
        <v-icon size="18" class="mr-1">$leftArrow</v-icon>
        <span class="text-capitalize detalhes-font">Voltar</span>
      </v-btn>
      <v-card v-if="!nextStep" color="white" class="ma-6 pa-6">
        <v-btn v-if="mobile" class="w-full" rounded color="primary" @click="startQrReader()"> Leitor </v-btn>
        <v-btn v-if="!mobile" class="w-full" rounded color="primary" @click="qrWeb = true"> Leitor </v-btn>
        <qrcode-stream v-if="qrWeb && !mobile" @decode="onDecode" class="mt-4"></qrcode-stream>
        <div v-if="!showQrReader">
          <span class="w-full d-flex justify-center"> Carregando </span>
          <v-row class="w-full justify-center mt-2">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
      <v-col sm="12" md="6" lg="6" class="ma-0 mt-4 pa-0">
      <v-card v-if="nextStep" color="white" class="ma-6 pa-6">
        <v-row class="align-center">
          <v-col cols='12'>
            <v-card-title>{{ voucher.clienteNome}} </v-card-title>
            <div v-if="!voucher.isDesconto" class="ml-3 font-weight-bold">Categoria: {{ voucher.categoria}}</div> 
            <div class="ml-3 font-weight-bold">{{ voucher.descricao}}</div> 
            <div v-if="!voucher.isDesconto" class="ml-3">Data do Evento: {{ voucher.dataEvento | filtraDH_withTime}}</div>
            <div v-if="voucher.numeroIngresso" class="ml-3">
              Código: {{ voucher.numeroIngresso }}
            </div>
            <v-card-text>
              <div class="font-weight-medium"> {{ voucher.nome }} </div>
              <div class="font-weight-medium"> {{ voucher.cpf }} </div>
            </v-card-text>
            <v-card-actions v-if="!loadingBaixa" class="justify-end">
              <v-btn rounded @click="nextStep = false"> Cancelar </v-btn>
              <v-btn color="primary" rounded @click="confirmCode()"> 
                Confirmar
                </v-btn>
            </v-card-actions>
              <v-row v-if="loadingBaixa" class="w-full justify-center mt-2">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
              </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-dialog
      v-model="hasError"
      max-width="350px"
      @click:outside="close"
      transition="dialog-transition"
    >
      <v-card>
        <v-col>
          <span>
            {{ errorText }}
          </span>
        </v-col>
        <v-col>
          <v-btn :color=" errorText ==  'QR Code Confirmado' ? 'success' : 'primary'" @click="hasError = false" class="mt-2" width="100%">
            Ok
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
import moment from "moment";
import { CheckService } from "@/services";
import { QrcodeStream } from 'vue-qrcode-reader'
//import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { QrScanner } from '@diningcity/capacitor-qr-scanner';

export default {
    components: {
     QrcodeStream
    },
  data() {
    return {
      mobile: eval(process.env.VUE_APP_IS_MOBILE),
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Endereço de email inválido";
        }
      },
      qrWeb: false,
      showQrReader: true,
      voucher: {},
      codigo: '', 
      produtos: [],
      nextStep: false,
      loadingProdutos: false,
      loadingCodigo: false,
      loadingBusca: false,
      produtosBusca: [],
      isDesconto: false,
      loadingBaixa: false,
      loadingBaixar: false,
      isMobile: eval(process.env.VUE_APP_IS_MOBILE),
      hasError: false,
      errorText: 'Aconteceu um erro',
    };
  },
  filters: {
    filtraDH(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "Inválido";
      }
    },
    filtraDH_withTime(val) {
      if (val) {
        return moment(val).format("LLLL");
      } else {
        return "Inválido";
      }
    }
  },
  mounted() {
    this.getProdutos();
  },
  methods: {
    async startQrReader(){
        const {result} =  await QrScanner.scanQrCode();
        this.showQrReader = false
        CheckService.leituraQrCode(result)
         .then(response => {
          this.voucher = response.data
          this.codigo = result
          this.isDesconto = response.data.isDesconto
          this.nextStep = true
          })
          .catch((err) => {
            this.hasError = true
            this.errorText = err.data
          })
          .finally(() => {
            this.showQrReader = true
          });

    }, 
    voltar() {
      this.$emit("close");
    },
    onDecode (decodedString) {
      this.codigo = decodedString
      this.showQrReader = false
      CheckService.leituraQrCode(decodedString)
        .then(response => {
          if(response){
          this.voucher = response.data
          this.codigo = decodedString
          this.isDesconto = response.data.isDesconto
          this.nextStep = true
          }
        })
        .catch((err) => {
          this.hasError = true
          this.errorText = err.data
        })
        .finally(() => {
          this.loadingCodigo = false;
          this.showQrReader = true
        });
    },
    confirmCode() {
      this.showQrReader = false
      this.loadingBaixa = true
      CheckService.confirmaLeitura(this.codigo)
      .then(response => {
        if(response){
          this.nextStep = false
          this.hasError = true
          this.errorText = this.isDesconto ? "Cupom Confirmado" : "Ingresso Confirmado"
        }
      })
      .catch((err) => {
        this.$root.showErro(err.data);
      })
      .finally(() => {
        this.showQrReader = true;
        this.loadingBaixa = false
      });
    }
  }
};
</script>

<style>
.w-full{
  width: 100%;
}
</style>