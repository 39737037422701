<template>
  <v-container v-if="loadingProdutos">
    <div class="text-center mt-16">
      <v-progress-circular
        indeterminate
        color="primary"
        size="80"
      ></v-progress-circular>
    </div>
  </v-container>
  <v-container :fluid="useFluid" v-else>
    <v-col>
      <v-row v-if="showCards">
        <v-col
          lg="4"
          md="6"
          sm="12"
          v-for="(produto, index) in produtos"
          :key="index"
        >
          <CardProduto :item="produto" @showDetalhes="showDetalhes" />
        </v-col>
      </v-row>
      <v-row v-else>
        <CardDetalhes :item="item" @voltar="voltar" />
      </v-row>
    </v-col>
    <v-dialog v-model="loadingRelatorio" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Carregando
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resumoDialog" fullscreen hide-overlay>
      <card-relatorio
        :data="resumo"
        :isCheckin="true"
        @onClose="resumoDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { CheckService } from "@/services";
import CardProduto from "@/components/cardWeb.vue";
import CardDetalhes from "./cardDetalhes.vue";

export default {
  components: {
    CardProduto,
    CardDetalhes,
  },
  data() {
    return {
      produtos: [],
      loadingProdutos: false,
      resumoDialog: false,
      showCards: true,
      item: {},
      loadingRelatorio: false,
      resumo: null,
    };
  },
  computed: {
    useFluid() {
      return this.$vuetify.breakpoint.lgAndDown;
    },
  },
  mounted() {
    this.getProdutos();
  },
  methods: {
    getProdutos() {
      this.loadingProdutos = true;
      CheckService.pagination()
        .then((response) => {
          this.produtos = response.data;
        })
        .catch(() => {
          this.$root.showErro("Falha ao buscar lista de produtos");
        })
        .finally(() => {
          this.loadingProdutos = false;
        });
    },
    showDetalhes(_produto) {
      this.showCards = false;
      this.item = _produto;
    },
    voltar() {
      this.showCards = true;
      this.item = {};
    },
  },
};
</script>
