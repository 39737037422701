<template>
  <v-card
    elevation="2"
    tile
    class="mb-4"
    max-width="700px"
  >
    <div class="cursor-pointer" @click="clickCard()">
    <v-row>
      <v-col cols="2" align="center">
        <v-avatar size="30" color="transparent" class="ml-3">
          <v-img contain :src="item.clienteLogo"></v-img>
        </v-avatar>
      </v-col>
      <v-col cols="6" class="">
        <v-row class="ma-0 pa-0">
          <span class="font-weight-bold text-subtitle-2">
            {{ item.clienteDefaultNome }}
          </span>
        </v-row>
        <v-row class="ma-0 pa-0 mt-n1">
          <span class="font-weight-light text-caption">{{
            item.categoria
          }}</span>
        </v-row>
      </v-col>
      <v-col cols="4" align="right" class="mt-4">
        <span class="font-weight-light text--secondary text-caption mr-2">{{
          item.dataPublicacao | filtraDH_fromNow
        }}</span>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col>
        <v-img
          contain
          :src="item.midiaUrl"
          :lazy-src="item.midiaUrl"
          height="250"
        ></v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0 mt-n4">
      <v-col cols="10">
        <v-row class="ma-0 pa-0 justify-space-between">
          <span class="font-weight-bold text-subtitle-1">
            {{ item.clienteNome }}
          </span>
        </v-row>
        <span
          v-if="item.valor != item.valorTotal"
          class="font-weight-bold text-subtitle-1"
        >
          De
          {{ `R$ ${asMoney(item.valorTotal)} por R$ ${asMoney(item.valor)}` }}
        </span>
        <v-row class="ma-0 pa-0 mt-n1">
          <span class="font-weight-light text-body-2">
            {{ item.descricaoDesconto }}
          </span>
        </v-row>
        <v-row v-if="item.dataEvento" class="ma-0 pa-0 mt-2">
          <span class="font-weight-light text-body-2">
            Data do Evento: {{ item.dataEvento | filtrarExtenso }}
          </span>
        </v-row>
        <v-row class="ma-0 pa-0 mt-2">
          <span class="font-weight-light text-body-2">
            Valido até: {{ item.validade | filtraDH }}
          </span>
        </v-row>
      </v-col>
    </v-row>
  </div>
    <v-divider v-if="hasActions" />
    <v-col v-if="hasActions" cols="12" class="d-flex justify-space-between">
      <v-btn @click="openResumo" color="primary"> Resumo</v-btn>
      <v-btn @click="handleCopy(item.comissarioLink)" color="primary"> Copiar Link</v-btn>
      <v-btn @click="openCortesia" color="primary"> Cortesias</v-btn>
    </v-col>
  
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    item: {
      type: [Object, Array],
      default: () => {},
    },
    hasClickDetails: {
      type: Boolean,
      default: false,
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //
    };
  },
  filters: {
    filtraDH_fromNow(val) {
      if (val) {
        return moment(val).fromNow();
      } else {
        return "Inválido";
      }
    },
    filtraDH(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "Inválido";
      }
    },
    filtrarExtenso(val) {
      if (val) {
        return moment(val).format("LLLL");
      } else {
        return "Inválido";
      }
    },
  },
  methods: {
    clickCard() {
      this.$emit("showDetalhes", this.item);
    },
    openCortesia(){
      this.$emit("cortesia", this.item)
    },
    openResumo(){
      this.$emit("resumo", this.item)
    },
    async handleCopy(link) {
      await navigator.clipboard.writeText(link);
      this.$root.showSucesso("Link Copiado para área de transferência");
    },
  },
};
</script>

<style>
.cursor-pointer{
  cursor: pointer;
}
</style>