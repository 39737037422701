<template>
  <div class="mb-16 mt-1">
    <v-app-bar elevation="0" dense class="ml-n4 mt-n2 mb-6">
    </v-app-bar>
    <!-- <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div> -->
    <v-card elevation="2" tile class="mt-2 mb-4" :width="isWeb ? '1000px' : '300px'">
          <div class="d-flex mt-2 ml-2 mb-2">
        <v-btn text color="primary" class="pa-0" @click="voltar">
          <v-icon size="18" class="mr-1">$leftArrow</v-icon>
          <span class="text-capitalize detalhes-font">Voltar</span>
        </v-btn>
      </div>
      <v-row>
        <v-col cols="2" align="center">
          <v-avatar size="40" color="transparent" class="ml-3">
            <v-img contain :src="item.clienteLogo"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="6">
          <v-row class="ma-0 pa-0">
            <span class="font-weight-bold text-subtitle-2">
              {{ item.clienteDefaultNome }}
            </span>
          </v-row>
          <v-row class="ma-0 pa-0 mt-n1">
            <span class="font-weight-light text-caption">{{
              item.categoria
            }}</span>
          </v-row>
        </v-col>
        <v-col cols="4" align="right" class="mt-4">
          <span class="font-weight-light text-caption mr-2">{{
            item.dataPublicacao | filtraDH_fromNow
          }}</span>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col>
          <v-img
            contain
            :src="item.midiaUrl"
            :lazy-src="item.midiaUrl"
            height="250"
          ></v-img>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 mt-n4">
        <v-col>
          <v-row class="ma-0 pa-0">
            <span class="font-weight-bold text-subtitle-1">
              {{ item.clienteNome }}
            </span>
          </v-row>
          <v-row class="ma-0 pa-0 mt-4">
            <span class="font-weight-light text-body-2">
              {{ item.descricao }}
            </span>
          </v-row>
          <v-row
            class="ma-0 pa-0 mt-4 justify-space-between"
            v-if="item.dataEvento"
          >
            <span class="font-weight-light text-body-2">
              Data do Evento: {{ item.dataEvento | filtrarExtenso }}
            </span>
          </v-row>
          <v-row class="ma-0 pa-0 mt-4 justify-space-between">
            <span class="font-weight-light text-body-2">
              Validado até: {{ item.validade | filtraDH }}
            </span>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="pb-1"></v-divider>
      <v-col>
        <span
          class="font-weight-light text-body-2"
          style="white-space: pre-wrap"
        >
          {{ item.descricaoDetalhe }}
        </span>
      </v-col>
      <v-col>
        <v-row class="ma-0 pa-0 mt-4">
          <span class="font-weight-light text-body-2">
            Local: {{ item.clienteNome }}
          </span>
        </v-row>
        <v-row class="ma-0 pa-0 mt-4">
          <span class="font-weight-light text-body-2">
            Endereço:
            {{
              `${item.logradouro} - nº ${item.numero} ${item.complemento &&
                item.complemento} - ${item.bairro} - ${item.estado}`
            }}
          </span>
        </v-row>
        <v-row class="ma-0 pa-0 mt-4">
          <span class="font-weight-light text-body-2">
            Telefone: {{ item.celular }}
          </span>
        </v-row>
      </v-col>
    </v-card>
    <v-row class="pl-3 pt-4">
      <v-card class="pa-2" :width="isWeb ? '1000px' : '300px'">
        <h2> Resumo </h2>

          <div v-if="resumo.length > 0">
              <v-col v-if="!isDesconto" cols="12" class="ml-6 text-body-1 font-weight-bold">
                <h2>Total</h2>
              </v-col>
              <v-col v-if="!isDesconto" cols="12" class="d-flex align-center flex-wrap">
                <v-col cols="12" class="d-flex flex-wrap justify-center">
                  <v-progress-circular
                    :rotate="180"
                    :size="100"
                    :width="15"
                    :value="calculatePercentual(item)"
                    color="primary"
                  >
                    {{(parseFloat((ticketSold/total) * 100)).toFixed(2)}}%
                  </v-progress-circular>
                </v-col>
                <v-row class="justify-center mt-2">
                <v-col cols="4" class="d-flex flex-wrap justify-center">
                  <v-icon class="mr-2" color="primary"> mdi-ticket-confirmation </v-icon>
                  <h2> {{ ticketEncerrado }} </h2>
                  <span class="text-subtitle-1 w-full text-center"> Ingressos Encerrados</span>
                </v-col>
                <v-col cols="4" class="d-flex flex-wrap justify-center">
                  <v-icon class="mr-2" color="primary"> mdi-ticket-confirmation </v-icon>
                  <h2> {{ ticketSold }} </h2>
                  <span class="text-subtitle-1 w-full text-center"> Ingressos Vendidos </span>
                </v-col>
                </v-row>
                <v-col cols="12">
                <v-divider v-if="resumo.lenght > 1" class="w-full" />
                </v-col>
              </v-col>
              <v-col v-if="!isDesconto" cols="12" class="ml-6 text-body-1 font-weight-bold">
                <h2>Categorias</h2>
              </v-col>
               <div v-for="item in resumo" :key="item.descricao">
                <v-col v-if="item.total > 0 && !item.isDesconto" cols="12" class="d-flex flex-wrap ml-12">
                  <span class="text-body-1 font-weight-bold">{{ item.descricao }} : {{ item.encerrados}} de {{ item.vendido}}</span> 
                  
                </v-col>
             
            </div>
            <div v-for="item in resumo" :key="item.descricao">
                <v-col v-if="item.total > 0 && item.isDesconto" cols="12" class="d-flex flex-wrap justify-center">
                  <v-progress-circular
                    :rotate="180"
                    :size="100"
                    :width="15"
                    :value="calculatePercentual(item)"
                    color="primary"
                  >
                    {{(parseFloat((ticketSold/total) * 100)).toFixed(2)}}%
                  </v-progress-circular>
                </v-col>
              <v-row v-if="item.isDesconto" class="justify-center mt-2">
                <v-col cols="4" class="d-flex flex-wrap justify-center">
                  <v-icon class="mr-2" color="primary"> mdi-ticket-confirmation </v-icon>
                  <h2> {{ item.vendido }} </h2>
                  <span v-if="item.isPago" class="text-subtitle-1 w-full text-center"> Cupons Vendidos </span>
                  <span v-if="!item.isPago" class="text-subtitle-1 w-full text-center"> Cupons Baixados </span>
                </v-col>
                <v-col v-if="item.total > 0 && !item.isDesconto" cols="4" class="d-flex flex-wrap justify-center">
                  <v-icon class="mr-2" color="primary"> mdi-ticket-confirmation </v-icon>
                  <h2> {{ item.total }} </h2>
                  <span class="text-subtitle-1 w-full text-center"> Total de Ingressos</span>
                </v-col>
                <v-col cols="4" class="d-flex flex-wrap justify-center">
                  <v-icon class="mr-2" color="primary"> mdi-ticket-confirmation </v-icon>
                  <h2> {{ item.encerrados }} </h2>
                  <span class="text-subtitle-1 w-full text-center"> Cupons Encerrados</span>
                </v-col>
                </v-row>
            </div>
          </div>
          <div v-if="resumo.length == 0" class="d-flex flex-wrap justify-center" >
            <v-col cols="4" class="d-flex flex-wrap justify-center">
              <v-icon class="mr-2" color="primary"> mdi-ticket-confirmation </v-icon>
              <h2> 0 </h2>
              <span class="text-subtitle-1 w-full text-center"> Total Adiquirido</span>
            </v-col>
            <v-col cols="4" class="d-flex flex-wrap justify-center">
                  <v-icon class="mr-2" color="primary"> mdi-ticket-confirmation </v-icon>
                  <h2> {{ 0 }} </h2>
                  <span class="text-subtitle-1 w-full text-center"> Total Encerrados</span>
            </v-col>
          </div>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { CheckService } from "@/services";
import moment from "moment";

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      carousel: 0,
      detalhes: [],
      loadingTickets: false,
      showTicket: false,
      loading: false,
      dialog: false,
      carrinho: [],
      saldo: null,
      loadingBaixar: false,
      loadingEncerrar: false,
      showEncerrarDescontoDialog: false,
      tickets: null,
      ingressoCounter: 0,
      mapa: null,
      mapDialog: false,
      maxCounter: 0,
      resumo: [],
      total: 0,
      ticketSold: 0,
      ticketEncerrado: 0
      
    };
  },

  filters: {
    filtraDH_withTime(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY HH:mm");
      } else {
        return "Inválido";
      }
    },
    filtraDH_diffFromNow(val) {
      if (val) {
        let now = moment();

        let date = moment(val)
          .add(1, "days")
          .startOf("day");

        let diffInDays = date.diff(now, "days");

        if (diffInDays === 0) {
          let diffInHours = date.diff(now, "hours");
          if (diffInHours === 0) {
            let diffInMinutes = date.diff(now, "minutes");
            return (
              (diffInMinutes === 1 ? "FALTA " : "FALTAM ") +
              diffInMinutes +
              (diffInMinutes === 1 ? " MINUTO" : " MINUTOS")
            );
          }

          return (
            (diffInHours === 1 ? "FALTA " : "FALTAM ") +
            diffInHours +
            (diffInHours === 1 ? " HORA" : " HORAS")
          );
        }

        return (
          (diffInDays === 1 ? "FALTA " : "FALTAM ") +
          diffInDays +
          (diffInDays === 1 ? " DIA" : " DIAS")
        );
      } else {
        return "Inválido";
      }
    },
    filtraDH_fromNow(val) {
      if (val) {
        return moment(val).fromNow();
      } else {
        return "Inválido";
      }
    },
    filtraDH(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "Inválido";
      }
    },
    filtrarExtenso(val) {
      if (val) {
        return moment(val).format("LLLL");
      } else {
        return "Inválido";
      }
    }
  },

  created(){
    this.getRelatorio()
  },

  computed: {
    isWeb() {
      return this.$vuetify.breakpoint.width > 750;
    },
    
    isDesconto(){
      if(this.resumo.some(el => el.isDesconto === true )){
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    voltar() {
      this.$emit("voltar");
    },

    calculatePercentual(){
      let percentual = (this.ticketSold/this.total)*100
      return percentual 
    },

    getRelatorio(){
      this.loadingResumo = true
      CheckService.resumoCategoria(this.item.id)
        .then((response) => {
          if(response.data.length == 0){
            return this.resumo = null
          }
          this.resumo = response.data
          this.maxCounter = this.resumo.map(i => {
          return {
            qtd: i.total,
            vendido: i.vendido,
            encerrado: i.encerrados
          }});
          this.maxCounter.forEach(element => {
            this.total += element.qtd
            this.ticketSold += element.vendido
            this.ticketEncerrado += element.encerrado
          });

        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingResumo = false
        });
    }
    
  }
};
</script>

<style lang="scss">
.w-full {
  width: 100%;
}

.w-1-2 {
  width: 40%;
}
.attetion-font {
  color: #c11718;
  font-size: 0.8rem;
  line-height: 1.25rem;
  letter-spacing: 0.02em !important;
  font-family: "Roboto", sans-serif !important;
}
.detalhes-font {
  color: #e30613;
  font-size: 18px;
}
.w-1-4 {
  width: 25%;
}
</style>
