<template>
  <v-row>
    <v-col sm="12" md="6" lg="6" class="ma-0 mt-4 pa-0">
      <v-btn text color="primary" class="px-6" @click="voltar">
        <v-icon size="18" class="mr-1">$leftArrow</v-icon>
        <span class="text-capitalize detalhes-font">Voltar</span>
      </v-btn>
      <v-card color="white" class="ma-6 pa-6">
        <v-form ref="formBaixaManual">
          <v-row>
            <v-select
              v-model="dados.produtoId"
              :items="produtos"
              item-text="descricao"
              item-value="id"
              label="Ingresso / Desconto"
              :loading="loadingProdutos"
              :disabled="loadingProdutos"
            ></v-select>
          </v-row>
          <v-row>
            <v-text-field
              v-model="dados.codigo"
              label="Número do ingresso"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-model="dados.proprietarioNome"
              label="Nome"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-mask="['###.###.###-##']"
              v-model="dados.proprietarioCPF"
              label="CPF"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              :rules="[rules.email]"
              v-model="dados.emailUsuarioExterno"
              label="E-mail"
            ></v-text-field>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loadingBusca"
              rounded
              large
              @click="cleanForm()"
              class="mr-4 mt-4"
            >
              Limpar
            </v-btn>
            <v-btn
              color="primary"
              :loading="loadingBusca"
              rounded
              large
              @click="buscar"
              class="mr-n5 mt-4"
            >
              Pesquisar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
    <v-col v-if="produtosBusca.length" sm="12" md="6" lg="6" class="ma-0 pa-0">
      <v-card
        v-for="(produto, index) in produtosBusca"
        :key="index"
        color="white"
        class="ma-6 pa-6 pt-4"
      >
        <br />
        <v-row v-if="produto.clienteNome" class="w-full justify-space-between">
          <p class="font-weight-bold">{{ produto.clienteNome }}</p>
          <v-chip v-if="produto.isCortesia" color="primary"> Cortesia </v-chip>
        </v-row>
        <v-row v-if="produto.descricao">
          <p class="font-weight-bold">{{ produto.descricao }}</p>
        </v-row>
        <v-row v-if="produto.especificacao && !produto.isDesconto">
          <p class="font-weight-bold"> Categoria: {{ produto.especificacao }}</p>
        </v-row>
        <v-row>
          <p v-if="produto.numeroIngresso">
            Código: {{ produto.numeroIngresso }}
          </p>
        </v-row>
        <v-row v-if="produto.propietarioNome || produto.dataCompra" class="mt-2">
          <p class="font-weight-medium" v-if="produto.propietarioNome">
            {{ produto.propietarioNome }}
          </p>
          <v-spacer></v-spacer>
          <p class="font-weight-light" v-if="produto.dataCompra">
            Baixa: {{ produto.dataCompra | filtraDH }}
          </p>
        </v-row>
        <v-row>
          <p v-if="produto.propietarioCPF" class="font-weight-medium">{{ produto.propietarioCPF }}</p>
          <v-spacer></v-spacer>
          <div class="font-weight-medium" v-if="produto.usuairoEncerradoNome">
            Encerrado por: {{ produto.usuairoEncerradoNome }} 
          </div>
          <v-btn
            large
            outlined
            color="primary"
            @click="baixar(produto)"
            :loading="loadingBaixar"
            v-if="!produto.isFinalizado"
          >
            <v-icon size="19" class="mr-2"> mdi-arrow-collapse-down </v-icon>
            Encerrar
          </v-btn>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { CheckService } from "@/services";

export default {
  data() {
    return {
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Endereço de email inválido";
        }
      },
      dados: {
        produtoId: null,
        codigo: null,
        proprietarioNome: null,
        proprietarioCPF: null,
        emailUsuarioExterno: null
      },
      produtos: [],
      loadingProdutos: false,
      loadingBusca: false,
      produtosBusca: [],
      loadingBaixar: false
    };
  },
  filters: {
    filtraDH(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "Inválido";
      }
    }
  },
  mounted() {
    this.getProdutos();
  },
  methods: {
    cleanForm(){
      this.dados = {
        produtoId: null,
        codigo: null,
        proprietarioNome: null,
        proprietarioCPF: null,
        emailUsuarioExterno: null,
      };
      this.produtosBusca= []
    },
    buscar() {
      this.produtosBusca = [];
      this.loadingBusca = true;
      CheckService.pesquisar(this.dados)
        .then(response => {
          if (response.data.length) {
            this.produtosBusca = response.data;
          } else {
            this.$root.showErro(
              "Nenhum resultado encontrado para os parâmetros informados"
            );
          }
        })
        .catch(() => {
          this.$root.showErro("Falha ao pesquisar produtos");
        })
        .finally(() => {
          this.loadingBusca = false;
        });
    },
    voltar() {
      this.$emit("close");
    },
    getProdutos() {
      this.loadingProdutos = true;
      CheckService.getTipoProdutos()
        .then(response => {
          this.produtos = response.data;
        })
        .catch(() => {
          this.$root.showErro("Falha ao buscar produtos");
        })
        .finally(() => {
          this.loadingProdutos = false;
        });
    },
    baixar(produto) {
      this.$root.showConfirmDialog(
        "Está ação não poderá ser desfeita! Deseja continuar?",
        "Atenção!",
        this.confirmaLeitura,
        produto
      );
    },
    confirmaLeitura(produto) {
      this.loadingBaixar = true;
      CheckService.confirmaLeitura(produto.codigo)
        .then(() => {
          produto.isFinalizado = true;
          this.$root.showSucesso("Baixa realizada com sucesso");
        })
        .catch((err) => {
          this.$root.showErro(err.data);
        })
        .finally(() => {
          this.loadingBaixar = false;
        });
    }
  }
};
</script>
